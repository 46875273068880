<template>
  <div class="lazy-scroll">
    <div class="wrapper-terms center-text">
      <h1 class="center-text uppercase">{{lang[this.appLang].referral_program}}<br></h1>
      <h3 class="small-top-padding" v-html="lang[this.appLang].referral_p1"></h3>
      <h3 class="small-top-padding" v-html="lang[this.appLang].referral_p2"></h3>
      <h3 class="small-top-padding" v-html="lang[this.appLang].referral_p3"></h3>
	  <br>
	  <div v-if="contentLoader" class="action-block-top full-top">
			<div class="default-text"></div>
	  </div>
	  <span v-else>
		  <div v-if="!isEmpty(user) && !isEmpty(affiliate)" class="bg-white edit-shadow content-boxed small-bottom small-top margin-center" style="width: 90%; padding: 25px;">
		  <h3 class="small-top-padding"> Status: <b>{{affiliate.status}}</b></h3>
		  <hr class="decoration decoration-lines-thin"/>
		  <h5 v-if="affiliate.status === 'pending'" class="small-top-padding">
			  We will review your application as soon as possible, <br>
			  and get back to you if we need more information.
			  <br>
			  <br>
			  Until then
			  <br>
			  <router-link :to="home" exact class="button white-button edit-shadow no-margin small-top">
				  KEEP TAPPIN'💥
			  </router-link>
		  </h5>

		  <span v-if="affiliate.status === 'approved'">
			  <h5> Referral link: <a @click="copy('tapni.co?ref=' + affiliate.ref_code)">tapni.co?ref={{affiliate.ref_code}}</a></h5>
			  <h5 class="small-top-padding"> Discount code: <a @click="copy(affiliate.discount_code)">{{affiliate.discount_code}}</a></h5>
			  <hr class="decoration decoration-lines-thin"/>
			  <h5 class="small-top-padding">Total orders: {{affiliate.totals.orders.toFixed(0)}}</h5>
			  <h5 class="small-top-padding">Total earned: {{affiliate.totals.earned.toFixed(2)}} €</h5>
			  <h5 class="small-top-padding">Total payout: {{affiliate.totals.paid.toFixed(2)}} €</h5>
			  <h5 class="small-top-padding">Total pending: {{affiliate.totals.pending.toFixed(2)}} €</h5>
		  </span>
	  </div>
		  <h5 v-if="!isEmpty(affiliate)" class="center-text font-14 full-bottom">
			  <span v-html="lang[this.appLang].referral_p4"></span> <a :href="'mailto:team@tapni.co?subject=' + lang[this.appLang].referral_program">ambassador@tapni.co</a>
		  </h5>
		  <span v-else>
			  <a @click="toggleAmbassadorModal" class="button white-button edit-shadow button-full pulse uppercase pointer">
				  {{lang[this.appLang].referral_apply_now}}
			  </a>
			  <hr class="decoration decoration-lines-thin"/>
			  <p class="center-text">
				<img src="https://cdn.tapni.co/icons/ambassador.png" class="responsive-image margin-center img-shadow" style="width: 20%;" :alt="lang[this.appLang].referral_program">
				<b class="half-top">{{lang[this.appLang].referral_p5}}</b>
				<br>
				{{lang[this.appLang].referral_p6}}
			  </p>
			  <hr class="decoration decoration-lines-thin">
			  <p class="center-text">
				<img src="https://cdn.tapni.co/icons/ambassador2.png" class="responsive-image margin-center img-shadow" style="width:20%;" :alt="lang[this.appLang].referral_program">
				<b class="half-top">{{lang[this.appLang].referral_p66}}</b>
				<br>
				{{lang[this.appLang].referral_p7}}
				<br>
				<br>
				<a @click="toggleAmbassadorModal" class="button white-button edit-shadow button-full pulse uppercase pointer">
					{{lang[this.appLang].referral_apply_now}}
				</a>
			  </p>
		  </span>
		  <hr class="decoration decoration-lines-thin"/>
		  <h5 class="font-14 small-top-padding small-bottom">
			  <b class="font-16">{{lang[this.appLang].referral_p8}}</b> <br>
			  <span v-html="lang[this.appLang].referral_p9"></span>
			  <div v-if="!isEmpty(affiliate) && affiliate.status === 'approved' && (affiliate.totals.earned - affiliate.totals.paid + affiliate.totals.pending) > 20" class="full-top">
				  <a @click="affiliatePayout" class="button white-button button-full" :class="{'edit-shadow pulse': !requestedPayout}">
					  <span v-if="!requestedPayout">{{lang[this.appLang].referral_p10}}</span>
					  <span v-else>{{lang[this.appLang].referral_p11}} ✔️</span>
				  </a>
			  </div>
		  </h5>
		  <h5 class="font-13 small-top-padding full-bottom">
			<a class="pull-left color-black" href="https://tapni.goaffpro.com/program-legal/terms">{{lang[this.appLang].terms_of_service}}</a>
			<a class="pull-right color-black" href="https://tapni.goaffpro.com/program-legal/privacy">{{lang[this.appLang].privacy_policy}}</a>
		  </h5>
		  <hr class="decoration decoration-lines-thin"/>
		  <p class="tapni-p margin-bottom-100"></p>
	  </span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {EventBus} from '../store/event-bus'
export default {
  name: 'Ambassador',
  async mounted () {
    EventBus.$on('toggleAmbassadorModal', (result) => {
      if (result === true) this.init()
    })
    this.init()
  },
  data() {
  	return {
		contentLoader: true
	}
  },
  computed: {
    ...mapState(['requestedPayout', 'affiliate'])
  },
  methods: {
    ...mapActions(['affiliatePayout', 'eventLog', 'getUser']),
    async init () {
	  if (this.user && this.user.username !== this.storage.username) {
		this.$store.commit('setLoading', true)
		await this.getUser({ username: this.storage.username })
      }
      this.eventLog({
        type: 'affiliate_page'
      })
      this.$store.commit('setInitialize', true)
      this.$store.commit('setLoading', false)
	  this.contentLoader = false
    },
    toggleAmbassadorModal () {
      EventBus.$emit('toggleAmbassadorModal')
    }
  },
}
</script>

<style>
</style>
